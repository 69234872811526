.containerPrincipal {
  padding: 80px 40px 0 40px;
  width: 100%;
}

.divCirculares {
  display: flex;
  flex-direction: column;
  padding: 60px 40px 40px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.10);
  margin-bottom: 200px;
}

.divCirculares a {
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--a2);
  margin-bottom: 20px;
  transition: .3s;
  width: fit-content;
  text-transform: uppercase;
}

.divCirculares a:hover {
  color: var(--a9)
}

@media screen and (max-width: 600px) {
  .containerPrincipal {
    padding: 60px 20px 0 20px;
    width: 100%;
  }

  .divCirculares {
    padding: 40px 20px;
    margin-bottom: 120px;
  }

  .divAno {
    margin-bottom: 40px;
  }

  .divCirculares a {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
    margin-left: 12px;
  }
}