.containerPrincipal {
  padding: 80px 40px 0 40px;
  width: 100%;
}

.divNovoManual {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.10);
  margin-bottom: 200px;
}

.divCadastro form {
  display: flex;
  flex-direction: column;
}

.divTipo {
  margin-bottom: 20px;
}

.divTipo label {
  display: block;
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
  padding-bottom: 2px;
}

.divTipo>select {
  width: 400px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #b3b3b3;
  background: var(--c-15);
  padding: 12px;
  padding-right: 0;
  color: var(--c7);
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  min-height: 48px;
}

.divTipo>select:focus,
.divTipo>select:hover {
  outline: none;
  border-color: #0B5690;
  background: white;
  box-shadow: 0px 0px 0px 3px rgba(11, 86, 144, 0.30);
}

.error {
  align-self: center;
  margin-top: 20px;
  color: var(--v2);
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.modal {
  position: absolute;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background: #ededed;
  padding: 40px 60px;
}

.modal h2 {
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: var(--a2);
  margin-bottom: 20px;
}

.modal p {
  white-space: pre-wrap;
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: var(--a1);
  margin-bottom: 32px;
  width: 100%;
}

.modal button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 10px;
  border-style: none;
  background: var(--a2);
  border-radius: 5px;
  font-family: var(--type-second);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--c1);
  width: fit-content;
}

.modal button:hover {
  cursor: pointer;
}