.containerPrincipal {
  padding: 80px 40px 0 40px;
  width: 100%;
}

.divCirculares {
  display: flex;
  flex-direction: column;
  padding: 60px 40px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.10);
  margin-bottom: 200px;
}

.divCirculares a {
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--a2);
  margin-bottom: 20px;
  transition: .3s;
  width: fit-content;
  margin-left: 20px;
  text-transform: uppercase;
}

.divCirculares a:hover {
  color: var(--a9)
}

.divAno {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.divAno:last-child {
  margin-bottom: 0;
}

.divTitulo {
  display: flex;
  justify-content: space-between;
}

.divTitulo button {
  margin-top: 0;
  align-self: flex-start;
}

.svg {
  margin-left: 20px;
  width: 24px;
  height: 24px;
  fill: var(--v2);
  transition: .3s;
}

.svg:hover {
  cursor: pointer;
  transform: translateY(-4px);
}

.circular {
  display: flex;
  align-items: start;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 560px;
  max-height: 80vh;
  padding: 40px;
  background: var(--w);
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  overflow: auto;
  outline: none;
}

.modal h2 {
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: var(--a2);
  margin-bottom: 20px;
}

.modal p {
  white-space: pre-wrap;
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: var(--c8);
  margin-bottom: 32px;
  width: 100%;
}

.botoes {
  width: 100%;
  display: flex;
  justify-content: space-around;
}