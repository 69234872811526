.titulo {
  font-family: var(--type-first);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  color: var(--c11);
  text-transform: uppercase;
  margin-bottom: 12px;
}

.divTitulo {
  margin-bottom: 40px;
}

.h3 {
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: var(--c11);
  text-transform: uppercase;
}

.dadosAut {
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--c11);
}

.dadosAut span {
  text-transform: uppercase;
  font-weight: 800;
}

@media screen and (max-width: 600px) {
  .titulo {
    font-size: 24px;
    line-height: 28px;
  }

  .dadosAut {
    font-size: 16px;
    line-height: 20px;
  }
}