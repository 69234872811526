.containerPrincipal {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 220px;
  max-width: 1600px;
}

@media (max-width: 1024px) {
  .containerPrincipal {
    padding: 0 100px;
  }
}

@media (max-width: 768px) {
  .containerPrincipal {
    padding: 0 40px;
  }
}

@media (max-width: 480px) {
  .containerPrincipal {
    padding: 0 20px;
  }
}