.containerPrincipal {
  padding: 80px 40px 0 40px;
  width: 100%;
}

.divNovosExtratos {
  display: flex;
  flex-direction: column;
  padding: 40px 60px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.10);
  margin-bottom: 200px;
}

.divNovosExtratos h2 {
  font-family: var(--type-first);
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  color: var(--c9);
  margin-bottom: 32px;
}

.divNovosExtratos form {
  display: flex;
  flex-direction: column;
}

.sep {
  display: inline;
  width: 100%;
  height: 2px;
  background: var(--c2);
  margin: 40px 0;
  border-radius: 4px;
}

.modal {
  position: absolute;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background: #ededed;
  padding: 40px 60px;
}

.modal h2 {
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: var(--a2);
  margin-bottom: 20px;
}

.modal p {
  white-space: pre-wrap;
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: var(--a1);
  margin-bottom: 32px;
  width: 100%;
}

.modal button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 10px;
  border-style: none;
  background: var(--a2);
  border-radius: 5px;
  font-family: var(--type-second);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--c1);
  width: fit-content;
}

.modal button:hover {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .containerPrincipal {
    padding: 60px 20px 0 20px;
    width: 100%;
  }

  .divNovosExtratos {
    padding: 32px;
  }
}