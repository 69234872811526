.containerPrincipal {
  display: grid;
  grid-template-columns: repeat(12, 60px);
  grid-column-gap: 40px;
  grid-row-gap: 0;
  margin: auto;
  padding-left: 220px;
  padding-right: 220px;
  max-width: 1600px;
}

.divIntro {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(12, 60px);
  grid-column-gap: 40px;
  margin: 0 -220px 120px;
  background: var(--a9);
  padding: 120px 220px;
}

.textos {
  grid-column: 1 / 7;
}

.textos h1 {
  color: var(--c1);
  font-family: Poppins;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  margin-bottom: 20px;
}

.textos p {
  color: var(--c1);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.divForm {
  grid-column: 8 / -1;
  border-radius: 24px;
  background: var(--w);
  padding: 40px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form h2 {
  align-self: center;
  color: var(--a9);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 40px;
}

.mensagem {
  display: flex;
  flex-direction: column;
  margin: 12px 0 32px;
}

.mensagem>label {
  color: var(--c10);
  font-family: var(--type-first);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
}

.mensagem textarea {
  width: 380px;
  height: 96px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--c2);
  background: var(--c-15);
  padding: 12px;
  border: 1px solid #ededed;
  color: var(--c7);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.mensagem textarea:hover,
.mensagem textarea:focus {
  outline: none;
  border-color: #0B5690;
  background: white;
  box-shadow: 0px 0px 0px 3px rgba(11, 86, 144, 0.30);
  ;
}

.form button {
  width: fit-content;
  align-self: center;
}

.divContatos {
  grid-column: 1 / -1;
}

.divContatos>h1 {
  font-family: var(--type-first);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  background: var(--ga2);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 80px;
}

.divContato {
  display: flex;
  margin: 0 -220px 80px;
  padding: 0 220px;
  background: var(--c1);
  opacity: 0;
}

.divContato:nth-child(odd) {
  display: flex;
  flex-direction: row-reverse;
  margin: 0;
  margin-bottom: 80px;
  padding: 0;
  background: var(--w);
}

.divContato:nth-child(odd) .tituloDados {
  display: flex;
  flex-direction: row-reverse;
}

.divContato:nth-child(odd) .divMapa {
  margin: 0;
  margin-left: 40px;
}

.divContato:nth-child(odd) .tituloDados img {
  margin: 0;
  margin-left: 12px;
}

.divContato:nth-child(odd) .divDados {
  align-items: end;
}

.divContato:nth-child(odd) .enderecoDados {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.divContato:nth-child(odd) .divDados .horaFunc {
  flex-direction: row-reverse;
}

.divContato:nth-child(odd) .divDados .horaFunc img {
  margin: 0;
  margin-left: 12px;
}

.divContato:last-child {
  margin-bottom: 200px;
}

.divMapa {
  min-width: 560px;
  max-height: 400px;
  margin-right: 40px;
}

.divDados {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
}

.tituloDados {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.tituloDados h1 {
  color: var(--c10);
  font-family: var(--type-first);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  text-transform: uppercase;
}

.tituloDados img {
  margin-right: 12px;
}

.enderecoDados {
  color: var(--c8);
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.enderecoDados p:last-child {
  margin-top: 32px;
  margin-bottom: 60px;
}

.horaFunc {
  display: flex;
}

.horaFunc img {
  margin-right: 12px;
}

.horaFunc p {
  color: var(--c9);
  font-family: var(--type-first);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.modal {
  position: absolute;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background: #ededed;
  padding: 40px 60px;
}

.modal button {
  background: var(--ga1);
  font-family: var(--type-second);
  font-weight: 500;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  text-align: center;
  padding: 8px 20px;
  font-size: 18px;
  line-height: 24px;
  margin-top: 32px;
}

.modal button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.2s;
}

.modal h2 {
  margin-bottom: 12px;
  color: #121212;
}

.modal h2>span {
  color: var(--a1);
}

.modal p {
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
}

.modal p span {
  font-weight: 600;
  color: #121212;
}

@media screen and (max-width: 767px) {
  .containerPrincipal {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100vw;
    /* Ajustes para telas menores */
  }

  .divIntro {
    display: flex;
    flex-direction: column;
    padding: 80px 20px;
    /* Ajustes para telas menores */
    margin: 0 0 120px;
  }

  .textos {
    margin-bottom: 32px;
    /* grid-column: 1 / span 12; */
    /* Ocupa todas as colunas em telas menores */
  }

  .textos h1 {
    font-size: 32px;
    line-height: 36px;
  }

  .textos p {
    font-size: 16px;
    line-height: 20px;
  }

  .mensagem textarea {
    width: 100%;
    height: 20vh;
  }

  .divContato {
    display: flex;
    width: 100vw;
    padding: 0;
    flex-direction: column;
    margin: 0 0 80px;
    height: auto;
  }

  .divContato:nth-child(odd) {
    flex-direction: column;
    margin: 0 0 80px;
  }

  .divContatos>h1 {
    font-size: 6.8vw;
    line-height: 7.6vw;
    margin-bottom: 60px;
    padding-left: 20px;
  }

  .divContato:nth-child(odd) .tituloDados {
    flex-direction: row;
  }

  .divContato:nth-child(odd) .divMapa {
    margin: 0;
  }

  .divContato:nth-child(odd) .tituloDados img {
    margin: 0 12px 0 0;
  }

  .divContato:nth-child(odd) .divDados {
    align-items: start;
  }

  .divContato:nth-child(odd) .enderecoDados {
    align-items: start;
  }

  .divContato:nth-child(odd) .divDados .horaFunc {
    flex-direction: row;
  }

  .divContato:nth-child(odd) .divDados .horaFunc img {
    margin: 0 8px 0 0;
  }

  .divDados {
    padding: 20px
  }

  .divMapa {
    max-width: 100vw;
    min-width: 0;
    height: 60%;
    margin-right: 0;
  }

  .tituloDados {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }

  .tituloDados h1 {
    font-size: 6vw;
    line-height: 7vw;
    text-transform: uppercase;
  }

  .tituloDados img {
    margin-right: 12px;
  }

  .enderecoDados {
    font-size: 14px;
    line-height: 18px;
  }

  .enderecoDados p:last-child {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .horaFunc img {
    margin-right: 12px;
  }

  .horaFunc p {
    font-size: 14px;
    line-height: 18px;
  }


}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .containerPrincipal {
    padding: 0 40px;
    /* Ajustes para telas de tamanho médio */
  }

  .divIntro {
    padding: 120px 40px;
    /* Ajustes para telas de tamanho médio */
  }
}