.containerPrincipal {
  padding: 80px 40px 0 40px;
  width: 100%;
}

.divTabelas {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.10);
  margin-bottom: 200px;
}


.divTabelas a {
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--a2);
  margin-bottom: 20px;
  transition: .3s;
  width: fit-content;
  text-transform: uppercase;
}

.divTabelas a:hover {
  color: var(--a9)
}

.divTabelas :last-child {
  margin-bottom: 0;
}

.divLinks {
  display: flex;
  flex-direction: column;
}

.divContainerLinks {
  margin-bottom: 40px;
}

.divContainerLinks h2 {
  font-family: var(--type-first);
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  color: var(--c9);
  margin-bottom: 32px;
}

.divTitulo {
  display: flex;
  justify-content: space-between;
}

.divTitulo button {
  margin-top: 0;
  align-self: flex-start;
}