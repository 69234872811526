.containerPrincipal {
  max-width: 1600px;
  padding: 120px 220px 200px;
  margin: auto;
}

.containerPrincipal h1 {
  font-family: var(--type-first);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  background: var(--ga2);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.containerPrincipal {
  max-width: 1600px;
  padding: 120px 220px 200px;
  margin: auto;
}

.containerPrincipal h1 {
  font-family: var(--type-first);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  background: var(--ga2);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Telas grandes */
@media (max-width: 1024px) {
  .containerPrincipal {
    padding: 100px 100px 150px;
  }

  .containerPrincipal h1 {
    font-size: 48px;
    line-height: 56px;
  }
}

/* Telas médias */
@media (max-width: 768px) {
  .containerPrincipal {
    padding: 80px 40px 100px;
  }

  .containerPrincipal h1 {
    font-size: 36px;
    line-height: 44px;
  }
}

/* Telas pequenas */
@media (max-width: 480px) {
  .containerPrincipal {
    padding: 60px 20px 80px;
  }

  .containerPrincipal h1 {
    font-size: 32px;
    line-height: 36px;
  }
}