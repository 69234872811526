.containerPrincipal {
  display: grid;
  max-width: 1600px;
  padding: 0 220px;
  margin: auto;
  grid-template-columns: repeat(12, 60px);
  gap: 40px;
  row-gap: 0;
}

.containerPrincipal>h2 {
  grid-column: span 8;
  color: var(--c7);
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 52px;
  text-transform: uppercase;
  margin-top: 120px;
  margin-bottom: 20px;
}

.containerPrincipal>h1 {
  grid-column: span 8;
  font-family: Poppins;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  background: var(--ga1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 32px;
}

@media (max-width: 600px) {
  .containerPrincipal {
    max-width: 100vw;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
  }

  .containerPrincipal>h2 {
    grid-column: span 10;
    margin-top: 8vh;
    font-size: 24px;
    line-height: 28px;
  }

  .containerPrincipal>h1 {
    grid-column: span 10;
    font-size: 36px;
    line-height: 40px;
  }
}

/* Telas grandes */