.containerPrincipal {
  padding: 60px 0 0 0;
  opacity: 0;
}

.containerPrincipal ul {
  max-width: 1600px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 -20px;
}

.containerPrincipal li {
  display: flex;
  padding: 16px 50px;
  border-left: 2px solid #d9d9d9;
  justify-content: center;
  align-items: center;
}

.containerPrincipal li .link {
  width: 200px;
  height: 100px;
}

.link {
  display: flex;
  align-items: center;
  transition: .4s;
}

.link:hover {
  transform: translateY(-5px);
}

.containerPrincipal img {
  max-width: 200px;
  max-height: 100px;
  margin: auto;
}

.containerPrincipal li:first-child,
.containerPrincipal li:nth-child(4n - 3) {
  border-left: none;
}

.containerPrincipal li:nth-child(n - 4) {
  border-bottom: 2px solid #D9D9D9;
}

.containerPrincipal li:nth-child(1),
.containerPrincipal li:nth-child(2),
.containerPrincipal li:nth-child(3),
.containerPrincipal li:nth-child(4) {
  border-top: none;
}

.containerPrincipal li:nth-last-child(-n + 4) {
  border-bottom: none;
}



@media (max-width: 1024px) {
  .containerPrincipal {
    padding-bottom: 100px;
  }

  .containerPrincipal h2 {
    margin-bottom: 20px;
  }

  .containerPrincipal ul {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 40px;
  }

  .containerPrincipal li:nth-child(n + 3) {
    border-top: 2px solid #d9d9d9;
  }

  .containerPrincipal li:nth-child(odd) {
    border-left: none;
  }

  .containerPrincipal img {
    max-width: 100px;
    max-height: 50px;
  }
}

@media (max-width: 480px) {
  .containerPrincipal ul {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 20px;
  }

  .containerPrincipal li {
    padding: 16px 20px;
    border-left: 2px solid #d9d9d9;
    border-right: none;
  }

  .containerPrincipal li:nth-child(n + 3) {
    border-top: 2px solid #d9d9d9;
  }

  .containerPrincipal li:nth-child(odd) {
    border-left: none;
  }

  .containerPrincipal li:nth-child(even) {
    border-left: 2px solid #d9d9d9;
  }

  .containerPrincipal li:nth-child(n + 3) {
    border-top: 2px solid #d9d9d9;
  }

  .containerPrincipal li {
    border-bottom: none;
  }

  .containerPrincipal li:last-child {
    border-bottom: none;
  }

  .containerPrincipal li .link {
    width: 100%;
    height: 10vh;
  }

  .containerPrincipal img {
    max-width: 32vw;
    max-height: 10vh;
  }
}