.containerPrincipal {
  max-width: 1600px;
  margin: auto;
  margin-bottom: 120px;
  opacity: 1;
}

.divPrincipal {
  display: flex;
  padding: 0 220px;
  background: var(--c2);
}

.divTextos {
  margin-left: 40px;
  margin-top: 40px;
}

.divTextos h1 {
  font-family: var(--type-first);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  background: var(--ga1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.divTextos h1 span {
  background: var(--gv1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.divTextos p {
  color: var(--C9, #4d4d4d);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 32px;
}

.divTextos p span {
  color: var(--C11, #1a1a1a);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

@media (max-width: 1200px) {
  .divPrincipal {
    padding: 0 100px;
  }

  .divTextos h1 {
    font-size: 48px;
    line-height: 56px;
  }

  .divTextos p {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 768px) {
  .divPrincipal {
    flex-direction: column;
    padding: 0 50px;
  }

  .divTextos {
    margin-left: 0;
    margin-top: 20px;
  }

  .divTextos h1 {
    font-size: 36px;
    line-height: 44px;
  }

  .divTextos p {
    font-size: 18px;
    line-height: 28px;
  }
}

@media (max-width: 480px) {
  .divPrincipal {
    padding: 0;
  }

  .divTextos h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .divTextos p,
  .divTextos p span {
    font-size: 16px;
    line-height: 24px;
  }

  .divTextos {
    padding: 0 20px 20px;
  }
}
