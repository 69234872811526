.containerMain {
  padding: 60px 40px 0 40px;
  width: 100%;
  margin-bottom: 200px;
}

.videoContainer {
  display: flex;
  padding: 0 20px;
  margin: 20px 0;
}

.dadosContainer {
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
}

.procurar {
  background: var(--c-15);
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 12px 0px 12px 12px;
  box-sizing: border-box;
  font-family: var(--type-second);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  transition: 0.2s;
  width: 100%;
  max-width: 560px;
  margin-bottom: 40px;
}

.procurar:focus,
.procurar:hover {
  outline: none;
  border-color: #0b5690;
  box-shadow: 0px 0px 0px 3px rgba(11, 86, 144, 0.3);
  background-color: var(--w);
}
