.containerPrincipal {
  max-width: 100%;
  padding: 200px 0;
  margin: auto;
  background: var(--gac1);
}

.form {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 560px;
  max-height: 592px;
  padding: 80px 60px;
  background: var(--w);
  border-radius: 12px;

  button {
    margin-top: 32px;
  }

  h3 {
    align-self: center;
    margin-bottom: 32px;
    color: var(--a4);
    font-family: var(--type-first);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  a {
    display: flex;
    justify-content: end;
    margin-top: -12px;

    p {
      color: var(--a1);
      font-family: var(--type-second);
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
    }
  }
}

.fourth {
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: none;
  width: 100%;
  background: var(--gab2);
  color: var(--c2);
  font-family: var(--type-second);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  transition: .3s;
}

.fourth:hover {
  transform: translateY(-4px);
  cursor: pointer;
  transition: .3s;
}

.error {
  align-self: center;
  margin-top: 20px;
  color: var(--v2);
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.verde {
  color: var(--vd2);
  font-family: var(--type-second);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.vermelho {
  color: var(--v2);
  font-family: var(--type-second);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.modal {
  position: absolute;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background: #ededed;
  padding: 40px 60px;
}

.modal h2 {
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: var(--a2);
  margin-bottom: 20px;
}

.modal p {
  white-space: pre-wrap;
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: var(--a1);
  margin-bottom: 32px;
  width: 100%;
}

.modal button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 10px;
  border-style: none;
  background: var(--a2);
  border-radius: 5px;
  font-family: var(--type-second);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--c1);
  width: fit-content;
}

.modal button:hover {
  cursor: pointer;
}