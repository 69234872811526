.main {
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: var(--gab);
  color: var(--c1);
  font-family: var(--type-second);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
  text-transform: uppercase;
  border: none;
  transition: 0.3s;
}

.main:hover {
  transform: translateY(-4px);
  cursor: pointer;
  transition: 0.3s;
}

.second {
  display: inline-flex;
  padding: 15px 31px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  height: 100%;
  border: 1px solid var(--ap2);
  background: var(--c1);
  transition: 0.5s;
  color: var(--ap);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
  text-transform: uppercase;
}

.second:hover {
  color: var(--c1);
  background: var(--gab);
  cursor: pointer;
  transition: 0.3s;
}

.third {
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: none;
  background: var(--ga1);
  color: var(--c2);
  font-family: var(--type-second);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  height: fit-content;
  text-transform: uppercase;
  transition: 0.3s;
}

.third:hover,
.fourth:hover,
.submit:hover,
.cancel:hover {
  transform: translateY(-4px);
  cursor: pointer;
  transition: 0.3s;
}

.fourth {
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: none;
  width: 100%;
  height: 100%;
  background: var(--gab2);
  color: var(--c2);
  font-family: var(--type-second);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  transition: 0.3s;
}

.third:disabled,
.fourth:disabled,
.submit:disabled,
.cancel:disabled,
.third:disabled:hover,
.fourth:disabled:hover,
.submit:disabled:hover,
.cancel:disabled:hover {
  background: #ccc;
  color: #666;
  transform: none;
  cursor: default;
}

.submit {
  border-radius: 12px;
  background: var(--gvb);
  border: none;
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--c2);
  font-family: var(--type-second);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
  text-transform: uppercase;
  margin-top: 32px;
  width: fit-content;
  align-self: center;
  transition: 0.3s;
}

.cancel {
  border-radius: 12px;
  background: var(--v1);
  border: none;
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--c2);
  font-family: var(--type-second);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 32px;
  width: fit-content;
  align-self: center;
  transition: 0.3s;
}

@media screen and (max-width: 600px) {
  .submit,
  .cancel {
    display: inline-flex;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 20px;
    /* 133.333% */
    margin-top: 32px;
  }
}
