.containerPrincipal {
  max-width: 1600px;
  padding: 0 220px;
  margin: auto;
  margin-bottom: 200px;
}

.containerPrincipal h1 {
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  background: var(--ga1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 60px;
}

.containerParceiros {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.containerParceiros a {
  display: flex;
  align-items: center;
}

.divParceiro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 260px;
  flex-shrink: 0;
  transition: .4s;
}

.divParceiro:hover {
  cursor: pointer;
  transform: translateY(-5px);
}

.divParceiro p {
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  background: var(--ga1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.containerPrincipal {
  max-width: 1600px;
  padding: 0 220px;
  margin: auto;
  margin-bottom: 200px;
}

.containerPrincipal h1 {
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  background: var(--ga1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 60px;
}

.containerParceiros {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  /* Para permitir que os elementos quebrem a linha em telas menores */
}

.containerParceiros a {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  /* Para dar espaço entre as linhas em telas menores */
}

.divParceiro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 260px;
  flex-shrink: 0;
  transition: .4s;
}

.divParceiro:hover {
  cursor: pointer;
  transform: translateY(-5px);
}

.divParceiro p {
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  background: var(--ga1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

/* Media Queries para tornar o layout responsivo */
@media (max-width: 1200px) {
  .containerPrincipal {
    padding: 0 100px;
    margin-bottom: 150px;
  }

  .containerPrincipal h1 {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 40px;
  }

  .divParceiro p {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 768px) {
  .containerPrincipal {
    padding: 0 50px;
    margin-bottom: 100px;
  }

  .containerPrincipal h1 {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 30px;
  }

  .divParceiro p {
    font-size: 18px;
    line-height: 24px;
  }

  .containerParceiros {
    justify-content: center;
    /* Centraliza os itens em telas menores */
  }

  .divParceiro {
    max-width: 200px;
  }
}

@media (max-width: 480px) {
  .containerPrincipal {
    padding: 0 20px;
    margin-bottom: 120px;
  }

  .containerParceiros a {
    margin: 12px;
  }

  .containerPrincipal h1 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .divParceiro p {
    font-size: 16px;
    line-height: 20px;
  }

  .divParceiro {
    max-width: 150px;
  }
}