.containerMain {
  padding: 80px 40px 0 40px;
  width: 100%;
}

.table {
  text-align: left;
  width: 100%;
}

.table tr :first-child {
  padding-left: 20px;
}

.table tr {
  display: grid;
  grid-template-columns: 4fr 2fr 4fr;
}

.table tr th {
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--c9);
  transition: .3s;
  width: fit-content;
  padding-bottom: 40px;
}

.table tr td {
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--a2);
  transition: .3s;
  width: fit-content;
  text-transform: uppercase;
}


.sep {
  display: inline-block;
  background: var(--c2);
  width: 100%;
  height: 2px;
  border-radius: 4px;
  margin-bottom: 18px;
  margin-top: 18px;
}

.sep:last-child {
  display: none;
}


.divCorpoClinico {
  padding: 40px 20px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.10);
  margin-bottom: 200px;
}

.divTitulo {
  display: flex;
  justify-content: space-between;
}

.divTitulo button {
  margin-top: 0;
  align-self: flex-start;
}

@media screen and (max-width: 600px) {
  .containerMain {
    padding: 60px 20px 0 20px;
    width: 100%;
  }

  .table tr {
    display: grid;
    grid-template-columns: 6fr 5fr 6fr;
  }

  .table tr :first-child {
    padding-left: 0;
  }

  .table tr th {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 32px;
  }

  .table tr td {
    font-size: 14px;
    line-height: 18px;
  }

  .divCorpoClinico {
    margin-bottom: 120px;
  }


  .divTitulo {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .divTitulo a {
    margin-top: -40px;
  }
}