.containerPrincipal {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin: auto;
  margin-bottom: 120px;
  padding: 120px 220px;
  background: var(--gpa1);
  border-radius: 20px;
  opacity: 0;
}

.divPrincipal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.divPrincipal h2 {
  color: var(--a0);
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  margin-bottom: 80px;
}

.divNumeros {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%
}

.containerTextos {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.divTextos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.divTextos p {
  color: var(--c1);
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-transform: uppercase;
}

.divTextos h1 {
  color: var(--a0);
  font-family: var(--type-second);
  font-size: 96px;
  font-style: normal;
  font-weight: 600;
  line-height: 106px;
}

.containerPrincipal {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin: auto;
  margin-bottom: 120px;
  padding: 120px 220px;
  background: var(--gpa1);
  border-radius: 20px;
  opacity: 0;
}

.divPrincipal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.divPrincipal h2 {
  color: var(--a0);
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  margin-bottom: 80px;
}

.divNumeros {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.containerTextos {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.divTextos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.divTextos p {
  color: var(--c1);
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-transform: uppercase;
}

.divTextos h1 {
  color: var(--a0);
  font-family: var(--type-second);
  font-size: 96px;
  font-style: normal;
  font-weight: 600;
  line-height: 106px;
}

/* Media Queries para tornar o layout responsivo */
@media (max-width: 1200px) {
  .containerPrincipal {
    padding: 80px 100px;
  }

  .divPrincipal h2 {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 60px;
  }

  .divTextos h1 {
    font-size: 72px;
    line-height: 80px;
  }

  .divTextos p {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .containerPrincipal {
    padding: 60px 50px;
  }

  .divNumeros {
    flex-direction: column;
    gap: 40px;
  }

  .divPrincipal h2 {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 40px;
  }

  .divTextos h1 {
    font-size: 60px;
    line-height: 68px;
  }

  .divTextos p {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: 480px) {
  .containerPrincipal {
    padding: 40px 20px;
  }

  .divPrincipal h2 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
  }

  .divTextos h1 {
    font-size: 48px;
    line-height: 56px;
  }

  .divTextos p {
    font-size: 14px;
    line-height: 20px;
  }
}