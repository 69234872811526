.divPrincipal {
  display: flex;
  width: 100%;
  margin-bottom: 60px;
}

.form {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 60px);
  grid-column-gap: 40px;
  grid-row-gap: 0;
}

.label {
  color: var(--c10);
  font-family: var(--type-first);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

@media (max-width: 1024px) {
  .form {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;
  }
}

@media (max-width: 768px) {
  .divPrincipal {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .form {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 10px;
  }
}

@media (max-width: 480px) {
  .divPrincipal {
    margin-bottom: 20px;
  }

  .form {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
}
