.containerPrincipal {
  padding: 80px 40px 0 40px;
  width: 100%;
}

.divFormulario {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.10);
  margin-bottom: 200px;
}


.divFormulario a {
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--a2);
  margin-bottom: 20px;
  transition: .3s;
  width: fit-content;
  text-transform: uppercase;
}

.divFormulario a:hover {
  color: var(--a9)
}

.divFormulario :last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  .containerPrincipal {
    padding: 60px 20px 0 20px;
    width: 100%;
  }

  .divFormulario {
    padding: 40px 20px;
    margin-bottom: 120px;
  }

  .divFormulario a {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
    margin-left: 12px;
  }
}