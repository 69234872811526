.divSetores {
  /* display: none; */
  width: 100%;
}

.divSetores label{
  display: block;
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
  padding-bottom: 2px;
}

.divSetores > select {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #b3b3b3;
  background: var(--c-15);
  padding: 12px;
  padding-right: 0;
  color: var(--c7);
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  min-height: 48px;
}

.divSetores > select:focus,
.divSetores > select:hover{
  outline: none;
  border-color: #0B5690;
  background: white;
  box-shadow: 0px 0px 0px 3px rgba(11, 86, 144, 0.30);;
}

/* @media screen and (max-width: 600px) {
  .divSetores {
    display: flex;
  }
} */
