.divAddFiles {
  background: var(--c-15);
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 12px 0px;
  box-sizing: border-box;
  font-family: var(--type-second);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  transition: 0.2s;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.tituloFiles {
  margin-bottom: 32px;
}

.divAddFiles:focus,
.divAddFiles:hover {
  cursor: pointer;
  outline: none;
  border-color: #0B5690;
  background: white;
  box-shadow: 0px 0px 0px 3px rgba(11, 86, 144, 0.30);
}

.divAddFiles h2 {
  font-family: var(--type-second);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--c9);
  margin-bottom: 20px;
}

.item {
  font-family: var(--type-second);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--a2);
  margin-bottom: 4px;
}