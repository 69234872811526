.wrapper {
  width: 100%;
  margin-bottom: 20px;
}

.input {
  background: var(--c-15);
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 12px 0px 12px 12px;
  box-sizing: border-box;
  font-family: var(--type-second);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  transition: 0.2s;
  width: 100%;
}

.input:focus,
.input:hover {
  outline: none;
  border-color: #0b5690;
  background: white;
  box-shadow: 0px 0px 0px 3px rgba(11, 86, 144, 0.3);
}

.label {
  color: var(--c10);
  font-family: var(--type-first);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.error {
  color: #f31;
  font-family: var(--type-second);
  font-size: 12px;
  line-height: 12px;
  margin-top: 4px;
}
