.containerPrincipal {
  display: flex;
  max-width: 1600px;
  height: calc(100vh - 88px);
  margin: auto;
  margin-bottom: 120px;
}

.divPrincipal {
  width: 100%;
  height: 100%;
  background-image: url(../../Assets/home-img.jpg);
  background-size: 100% 100%;
  /* background-position: center center; */
}

.divFiltro {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(179, 179, 179, 0.40);
}

.divTextos {
  display: flex;
  max-width: 560px;
  flex-direction: column;
  margin-left: 220px;
}

.divTextos h1 {
  font-family: var(--type-first);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  background: var(--ga1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.divTextos h1 span {
  background: var(--gv1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.divTextos p {
  color: var(--C9, #4D4D4D);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 32px;
}

.divTextos p span {
  color: var(--C11, #1A1A1A);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}



.divTextos button {
  width: fit-content;
}

@media (max-width: 1200px) {
  .divTextos {
    margin-left: 120px;
    max-width: 480px;
  }

  .divTextos h1 {
    font-size: 48px;
    line-height: 56px;
  }

  .divTextos p {
    font-size: 20px;
    line-height: 30px;
  }

  .divTextos p span {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 768px) {
  .containerPrincipal {
    flex-direction: column;
    height: auto;
    margin-bottom: 60px;
  }

  .divPrincipal {
    height: 36vh;
  }

  .divTextos {
    margin: 0 20px;
    max-width: 100%;
  }

  .divTextos h1 {
    font-size: 36px;
    line-height: 44px;
  }

  .divTextos p {
    font-size: 18px;
    line-height: 28px;
  }

  .divTextos p span {
    font-size: 18px;
    line-height: 28px;
  }
}

@media (max-width: 480px) {
  .divTextos h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .divTextos p {
    font-size: 16px;
    line-height: 24px;
  }

  .divTextos p span {
    font-size: 16px;
    line-height: 24px;
  }
}