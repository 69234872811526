.divPrincipal {
  background: var(--c1);
  padding: 120px 220px;
  margin: 0 -220px;
}

.divPrincipal>h1 {
  font-family: var(--type-first);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  background: var(--ga2);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 40px;
}

.divPrincipal>p {
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--c8);
}

.divInput {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 32px;
}

.buttonBusca {
  position: absolute;
  left: 512px;
  min-width: 36px;
  min-height: 36px;
  background: url('../../Assets/lupa.svg') center center no-repeat;
  background-size: 36px;
  border: none;
  cursor: pointer;
}

.buttonBusca:hover {
  transform: scale(1.1);
  transition: .3s;
}

.procurar {
  background: var(--c-15);
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 12px 0px 12px 12px;
  box-sizing: border-box;
  font-family: var(--type-second);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  transition: 0.2s;
  width: 100%;
  max-width: 560px;
}

.procurar:focus,
.procurar:hover {
  outline: none;
  border-color: #0B5690;
  box-shadow: 0px 0px 0px 3px rgba(11, 86, 144, 0.30);
  background-color: var(--w);
}

@media (max-width: 768px) {
  .divPrincipal {
    padding: 80px 40px;
    margin: 0 -40px;
  }

  .divPrincipal>h1 {
    font-size: 36px;
    line-height: 44px;
  }

  .divPrincipal>p {
    font-size: 16px;
    line-height: 20px;
  }

  .buttonBusca {
    left: 70%;
  }
}

/* Telas pequenas */
@media (max-width: 480px) {
  .divPrincipal {
    padding: 60px 20px;
    margin: 0 -20px;
  }

  .divPrincipal>h1 {
    font-size: 24px;
    line-height: 28px;
  }

  .divPrincipal>p {
    font-size: 16px;
    line-height: 20px;
  }

  .divInput {
    flex-direction: column;
    align-items: flex-start;
  }

  .buttonBusca {
    /* position: static; */
    left: 78vw;
    margin-top: 8px;
  }

  .procurar {
    max-width: 100%;
  }
}