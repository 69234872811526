.containerMain {
  padding: 60px 40px 0 40px;
  width: 100%;
  margin-bottom: 200px;
}

.videoFuncs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.videoButtons {
  display: flex;
  flex-direction: row;
  margin: 28px 0;
}

.videoContainer {
  display: flex;
  padding: 0 20px;
  margin: 20px 0;
}

.dadosContainer {
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
}

.svg {
  margin-left: 20px;
  width: 24px;
  height: 24px;
  fill: var(--v2);
  transition: 0.3s;
}

.svgEditar {
  margin-left: 20px;
  width: 24px;
  height: 24px;
  fill: var(--vd2);
  transition: 0.3s;
}

.svg:hover,
.svgEditar:hover {
  cursor: pointer;
  transform: translateY(-4px);
}

.procurar {
  background: var(--c-15);
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 12px 0px 12px 12px;
  box-sizing: border-box;
  font-family: var(--type-second);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  transition: 0.2s;
  width: 100%;
  max-width: 560px;
  margin-bottom: 40px;
}

.procurar:focus,
.procurar:hover {
  outline: none;
  border-color: #0b5690;
  box-shadow: 0px 0px 0px 3px rgba(11, 86, 144, 0.3);
  background-color: var(--w);
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 560px;
  max-height: 80vh;
  padding: 40px;
  background: var(--w);
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  overflow: auto;
  outline: none;
}

.modal h2 {
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: var(--a2);
  margin-bottom: 20px;
}

.modal p {
  white-space: pre-wrap;
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: var(--c8);
  margin-bottom: 32px;
  width: 100%;
}

.botoes {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
