.subTitulo {
  font-family: var(--type-first);
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  color: var(--c9);
  margin-bottom: 32px;
  transition: .3s;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .subTitulo {
    font-size: 24px;
    line-height: 28px;
  }
}