.containerMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 80px 60px;
  align-items: center;
}

.video {
  max-width: 960px;
  max-height: 540px;
  min-width: 480px;
  min-height: 270px;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.containerDados {
  display: flex;
  flex-direction: column;
  max-width: 960px;
  min-width: 480px;
  width: 100%;
  padding: 12px;
}

.containerDados h1 {
  font-family: var(--type-second);
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  white-space: normal;
  color: var(--c11);
  margin-bottom: 12px;
}

.containerDados p {
  font-family: var(--type-second);
  color: var(--c9);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
