.divPrincipal {
  padding: 80px 0 200px;
  transition: .3s;
}

.divPrincipal>h1 {
  color: var(--c8);
  font-family: var(--type-first);
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 52px;
  margin-bottom: 20px;
}

.divPrincipal>p {
  color: var(--c8);
  font-family: var(--type-second);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  margin-top: 20px;
}

.separador {
  display: block;
  height: 2px;
  max-width: calc(100% + 20px);
  background: var(--c3);
  margin-left: -20px;
  margin-bottom: 40px;
}

.resultado {
  margin-bottom: 40px;
}

.resultado>h3 {
  color: var(--a1);
  font-family: var(--type-second);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}

.resultado>p {
  color: var(--c7);
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

}

.clinicaMedico {
  margin-left: 20px;
  margin-top: 20px;
  transition: .3s;
}

.nomeClinica {
  color: var(--a1);
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  transition: .3s;
}

.dadosClinica {
  color: var(--c7);
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.medicosClinica {
  margin-left: 20px;
  margin-top: 20px;
}

.medicoClinica {
  color: var(--a1);
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
  transition: .3s;
}

.clinicaMedico:hover,
.clinicaMedico:hover .nomeClinica,
.medicoClinica:hover {
  cursor: pointer;
  color: var(--a9)
}

@media (max-width: 768px) {
  .divPrincipal {
    padding: 60px 20px 120px;
  }

  .divPrincipal>h1 {
    font-size: 36px;
    line-height: 40px;
  }

  .divPrincipal>p {
    font-size: 20px;
    line-height: 28px;
  }

  .separador {
    margin-bottom: 30px;
  }

  .resultado>h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .resultado>p {
    font-size: 14px;
    line-height: 20px;
  }

  .clinicaMedico,
  .nomeClinica,
  .medicoClinica {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 480px) {
  .divPrincipal {
    padding: 40px 10px 80px;
  }

  .divPrincipal>h1 {
    font-size: 20px;
    line-height: 24px;
  }

  .divPrincipal>p {
    font-size: 16px;
    line-height: 20px;
  }

  .resultado>h3 {
    font-size: 16px;
    line-height: 20px;
  }

  .resultado>p {
    font-size: 12px;
    line-height: 18px;
  }

  .clinicaMedico,
  .nomeClinica,
  .medicoClinica {
    font-size: 12px;
    line-height: 18px;
  }

  .dadosClinica {
    font-size: 12px;
    line-height: 18px;
  }
}