.footer {
  display: grid;
  max-width: 100vw;
  padding-left: calc((100vw - 1600px - 17px) / 2 + 220px);
  padding-right: calc((100vw - 1600px - 17px) / 2 + 220px);
  padding-top: 60px;
  padding-bottom: 32px;
  grid-template-columns: repeat(12, 60px);
  gap: 40px;
  grid-row-gap: 0;
  background: var(--c1);
}

.footer>img {
  grid-column: span 3;
}

.divFooter {
  grid-column: span 6;
  color: var(--C8, #666);
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.footer>p {
  color: var(--c6);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.copyright {
  grid-column: 1 / -1;
}

.titleFooter {
  color: var(--C11, var(--GAP1, #1A1A1A));
  font-family: var(--type-second);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;
  margin-bottom: 32px;
}

.divUnidades {
  margin-bottom: 32px;
}

.divUnidade {
  margin-bottom: 20px;
}

.divUnidade:last-child {
  margin-bottom: 0;
}

.divEndereco {
  display: flex;
  align-items: center;
  margin-left: -36px;
}

.divEndereco img {
  margin-right: 12px;
}

.divMenu {
  display: flex;
  flex-direction: column;
}

.selected {
  color: var(--ap);
  font-weight: 700;
  font-family: var(--type-second);
  font-size: 18px;
  line-height: 32px;
  font-style: normal;
  width: max-content;
}

.itemMenu {
  color: var(--c8);
  font-family: var(--type-second);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  width: max-content;
  transition: .3s;
}

.itemMenu:hover {
  color: var(--ap);
  transform: translateX(4px);
  transition: .3s;
}

.divContatos {
  margin-bottom: 60px;
}

.divContato {
  display: flex;
  margin-left: -36px;
  margin-bottom: 8px;
  font-size: 18px;
}

.divContato img {
  margin-right: 12px;
}

.divSocialMedia {
  display: flex;
  margin-top: 24px
}

.divSocialMedia img {
  margin-right: 32px;
}

@media (max-width: 1200px) {
  .footer {
    padding-left: 50px;
    padding-right: 50px;
  }

  .footer>img {
    grid-column: span 3;
  }

  .divFooter {
    grid-column: span 6;
  }

  .copyright {
    grid-column: span 12;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .footer {
    padding-left: 20px;
    padding-right: 20px;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;
  }

  .footer>img {
    grid-column: span 12;
    justify-self: center;
  }

  .divFooter {
    grid-column: span 12;
  }

  .divInfos {
    grid-column: span 12;
  }

  .copyright {
    grid-column: span 12;
    text-align: center;
  }

  .divSocialMedia img {
    margin-right: 16px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px 16px;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .divMenu {
    align-items: center;
  }

  .divEndereco {
    align-items: flex-start;
    margin-left: 0;
    width: 100%;
  }

  .divContato {
    margin-left: 0;
    width: 100%;
    align-items: flex-start;
  }

  .divSocialMedia {
    justify-content: space-around;
  }

  .footer>img {
    grid-column: span 1;
    justify-self: center;
  }

  .divFooter {
    grid-column: span 1;
    text-align: center;
  }

  .divInfos {
    grid-column: span 1;
    text-align: center;
    margin-bottom: 40px;
  }

  .copyright {
    grid-column: span 1;
    text-align: center;
  }

  .divSocialMedia img {
    margin-right: 8px;
  }

  .titleFooter {
    font-size: 18px;
  }

  .divContato {
    font-size: 14px;
  }

  .itemMenu,
  .selected {
    font-size: 16px;
  }
}