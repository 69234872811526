.form {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 40px;
  width: 100%;
}

.ativarAviso {
  grid-column: 1 / -1;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  font-family: var(--type-second);
  font-weight: 500;
  color: var(--c9);
  line-height: 20px;
  font-size: 20px;
}

.ativarAviso input {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

/* .ativarAviso input {
  display: none;
}

.ativarAviso .checkmark {
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  margin-right: 8px;
}

.alterarAviso input:checked+.checkmark {
  background-color: #4caf50;
}

.alterarAviso .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.alterarAviso input:checked+.checkmark:after {
  display: block;
} */