.divAnexo {
  display: flex;
  align-items: center;
  width: 100%;
}

.anexo {
  display: inline-flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-conte, nt;
  color: var(--c2);
  font-family: var(--type-second);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
  text-transform: uppercase;
  border-radius: 24px;
  background: var(--ap2);
  border: none;
  cursor: pointer;
  max-height: 48px;
  margin-left: 40px;
}

.file {
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 10px;
}

@media (max-width: 1024px) {
  .anexo {
    margin-left: 20px;
  }
}

@media (max-width: 768px) {
  .divAnexo {
    flex-direction: column;
    align-items: flex-start;
  }

  .anexo {
    margin-left: 0;
    margin-bottom: 20px;
    align-self: center;
    width: 50%;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
  }

  .file {
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .anexo {
    font-size: 14px;
    padding: 8px;
    max-height: none;
  }

  .file {
    padding: 8px;
  }
}