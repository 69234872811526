.divTexto {
  padding: 40px 40px 60px 40px;
}

.texto1 {
  border-radius: 12px;
  background: var(--ap2);
  margin-bottom: 60px;
  grid-column: span 8;
}

.texto2 {
  border-radius: 12px;
  background: var(--c2);
  margin-bottom: 60px;
  grid-column: 5 / -1;
}

.texto3 {
  border-radius: 12px;
  background: var(--ap);
  margin-bottom: 60px;
  grid-column: 2 / 10;
}

.texto4 {
  border-radius: 12px;
  background: var(--a0);
  grid-column: 4 / 12;
  margin-bottom: 200px;
}

.texto1 h1 {
  color: var(--c1);
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  /* 108.333% */
  text-transform: uppercase;
  margin-bottom: 20px;
}

.texto1 p {
  color: var(--c1);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.texto2 h1 {
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  /* 108.333% */
  text-transform: uppercase;
  background: var(--ga1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.texto2 p {
  color: var(--c8);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.texto3 h1 {
  color: var(--c2);
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.texto3 p {
  color: var(--c2);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.texto4 h1 {
  color: var(--c10);
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  /* 108.333% */
  text-transform: uppercase;
  margin-bottom: 20px;
}

.texto4 p {
  color: var(--c9);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

@media (max-width: 600px) {
  .texto1 {
    grid-column: span 12;
  }

  .texto2 {
    grid-column: span 12;
  }

  .texto3 {
    grid-column: span 12;
  }

  .texto4 {
    grid-column: span 12;
    margin-bottom: 100px;
  }

  .texto1 h1,
  .texto2 h1,
  .texto3 h1,
  .texto4 h1 {
    font-size: 28px;
    line-height: 32px;
  }

  .texto1 p,
  .texto2 p,
  .texto3 p,
  .texto4 p {
    font-size: 16px;
    line-height: 22px;
  }
}