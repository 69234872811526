.form {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-column-gap: 40px;
  width: 100%;
}

.divTipo {
  margin-bottom: 20px;
}

.divTipo label {
  display: block;
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
  padding-bottom: 2px;
}

.divTipo > select {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #b3b3b3;
  background: var(--c-15);
  padding: 12px;
  padding-right: 0;
  color: var(--c7);
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  min-height: 48px;
}

.divTipo > select:focus,
.divTipo > select:hover {
  outline: none;
  border-color: #0b5690;
  background: white;
  box-shadow: 0px 0px 0px 3px rgba(11, 86, 144, 0.3);
}

.mensagem {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.mensagem > label {
  color: var(--c10);
  font-family: var(--type-first);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
}

.mensagem textarea {
  width: 100%;
  height: 240px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--c2);
  background: var(--c-15);
  padding: 12px;
  border: 1px solid #ededed;
  color: var(--c7);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.mensagem textarea:hover,
.mensagem textarea:focus {
  outline: none;
  border-color: #0b5690;
  background: white;
  box-shadow: 0px 0px 0px 3px rgba(11, 86, 144, 0.3);
}

@media (max-width: 600px) {
  .form {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
}
