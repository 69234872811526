@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&family=Roboto:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: auto;
  --type-first: Poppins, sans-serif;
  --type-second: Roboto;
  --gab: linear-gradient(180deg, #1B6095 0%, #228691 100%);
  --gab2: linear-gradient(180deg, #0E71BE 0%, #17A5B5 100%);
  --ga1: linear-gradient(180deg, #1B6095 0%, #2784CB 100%);
  --ga2: linear-gradient(90deg, #1B6095 0%, #2784CB 100%);
  --gac1: linear-gradient(180deg, #182E3F 0%, #F5F5F5 100%);
  --gac2: linear-gradient(180deg, #182E3F 0%, #666 100%);
  --gpa1: linear-gradient(289deg, #1A1A1A 84.31%, #0B5690 100%);
  --gpa2: linear-gradient(180deg, #121833 0%, #1A1A1A 100%);
  --gv1: linear-gradient(180deg, #AC0000 0%, #EF2B2B 100%);
  --gv2: linear-gradient(90deg, #AC0000 0%, #EF2B2B 100%);
  --gvb: linear-gradient(180deg, #1D562D 0%, #205524 100%);
  --gar1: linear-gradient(270deg, #AB0067 0%, #2784CB 100%);
  --ap: #1B6095;
  --ap2: #0B5690;
  --a0: #94D1FF;
  --a1: #128BE8;
  --a2: #107DD0;
  --a3: #0E71BE;
  --a4: #0D66AB;
  --a9: #183954;
  --v1: #E30016;
  --v2: #AC0000;
  --vd: #00AB30;
  --vd2: #205524;
  --w: #FFF;
  --c1: #F5F5F5;
  --c-15: #F7F7F7;
  --c2: #EBEBEB;
  --c3: #E0E0E0;
  --c4: #CCCCCC;
  --c5: #B3B3B3;
  --c6: #999999;
  --c7: #808080;
  --c8: #666666;
  --c9: #4D4D4D;
  --c10: #333333;
  --c11: #1A1A1A;
  --c12: #000000;
  font-family: var(--type-first);
  font-family: var(--type-second);
  padding-top: 0;
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

img {
  display: block;
  max-width: 100%;
}

button,
input {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
}

.col1 {
  grid-column: span 1;
  margin-bottom: 20px;
}

.spanAll {
  grid-column: 1/-1;
}

.col2 {
  grid-column: span 2;
  margin-bottom: 20px;
}

.col3 {
  grid-column: span 3;
  margin-bottom: 20px;
}

.col4 {
  grid-column: span 4;
  margin-bottom: 20px;
}

.col5 {
  grid-column: span 5;
  margin-bottom: 20px;
}

.col6 {
  grid-column: span 6;
  margin-bottom: 20px;
}

.col8 {
  grid-column: span 8;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-80px);
  animation: animeLeft 1s forwards;
}

.animeRight {
  opacity: 0;
  transform: translateX(80px);
  animation: animeRight 1s forwards;
}

.animeLeftProd {
  opacity: 0;
  transform: translateX(-40px);
  animation: animeLeft 1s forwards;
}

.input100 {
  margin-bottom: 20px;
  width: 100%;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

@keyframes animeRight {
  to {
    opacity: 1;
    transform: initial;
  }
}

@media (max-width: 600px) {
  body {
    max-width: 100vw;
  }

  .col1,
  .spanAll .col2,
  .col3,
  .col4,
  .col5,
  .col6,
  .col8 {
    grid-column: 1/-1;
    margin-bottom: 20px;
  }


}