.containerPrincipal {
  padding: 80px 40px 0 40px;
  width: 100%;
}

.alerta {
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--c8);
  transition: .3s;
  width: fit-content;
}

.divCirculares {
  display: flex;
  flex-direction: column;
  /* width: fit-content; */
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.10);
  margin-bottom: 200px;
}

.divCirculares a {
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--a2);
  margin-bottom: 20px;
  transition: .3s;
  width: fit-content;
  margin-left: 20px;
}

.divCirculares a:last-child {
  margin-bottom: 0;
}

.divCirculares a:hover {
  color: var(--a9)
}

.divAno {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.divAno:last-child {
  margin-bottom: 0;
}

.divTitulo {
  display: flex;
  justify-content: space-between;
}

.divTitulo button {
  margin-top: 0;
  align-self: flex-start;
}