.modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 760px;
  max-height: 80vh;
  padding: 60px;
  background: var(--w);
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  overflow: auto;
  outline: none;
}

.modal h2 {
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: var(--a2);
  margin-bottom: 20px;
}

.modal pre {
  white-space: pre-wrap;
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: var(--c8);
  margin-bottom: 32px;
  width: 100%;
}

.modal button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 10px;
  border-style: none;
  background: #b3b3b3;
  border-radius: 5px;
  font-family: var(--type-second);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: #595959;
  align-self: flex-end;
  width: fit-content;
}

@media screen and (max-width: 600px) {
  .modal {
    padding: 32px;
    width: calc(100vw - 40px);
  }

  .modal h2 {
    font-size: 24px;
    line-height: 28px;
  }

  .modal pre {
    font-size: 16px;
    line-height: 24px;
  }

  .modal button {
    font-size: 16px;
    line-height: 24px;
  }
}