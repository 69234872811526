.containerMain {
  padding: 80px 40px 0 40px;
  width: 100%;
}

.divForm {
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.10);
  margin-bottom: 200px;
}

.divDocumentos {
  margin: 20px 0 0 20px;
}

.divDocumentos h2 {
  font-family: var(--type-first);
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  color: var(--ap2);
  margin-bottom: 32px;
}

.divLinks {
  display: flex;
  flex-direction: column;
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--a2);
  margin-bottom: 20px;
}

.divLinks a {
  margin-bottom: 20px;
  color: var(--a2);
  transition: .3s;
}

.divLinks a:hover {
  color: var(--a9);
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.modal {
  position: absolute;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background: #ededed;
  padding: 40px 60px;
}

/* .modal button {
  background: var(--ga1);
  font-family: var(--type-second);
  font-weight: 500;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  text-align: center;
  padding: 8px 20px;
  font-size: 18px;
  line-height: 24px;
  margin-top: 32px;
}

.modal button:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.2s;
} */

.modal h2 {
  font-family: var(--type-first);
  margin-bottom: 12px;
  color: #121212;
}

.modal h2>span {
  color: var(--a1);
}

.modal p {
  font-family: var(--type-second);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 32px;
  color: #595959;
}

.modal p span {
  font-weight: 600;
  color: #121212;
}