.containerPrincipal {
  display: grid;
  grid-template-columns: repeat(12, 60px);
  grid-column-gap: 40px;
  grid-row-gap: 0;
  margin: auto;
  padding: 0 220px;
  max-width: 1600px;
}

.divIntro {
  display: grid;
  grid-template-columns: repeat(12, 60px);
  grid-column-gap: 40px;
  margin: 0 -220px;
  padding: 120px 220px;
  grid-column: 1 / -1;
  background: var(--gac2);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
}

.divIntro h1 {
  color: var(--c1);
  font-family: var(--type-first);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  margin-bottom: 32px;
  grid-column: 1 / -1;
}

.divIntro p {
  color: var(--w);
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  grid-column: 1 / 7;
}

.divForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 -220px;
  padding: 120px 220px 200px;
  grid-column: 1 / -1;
  background: var(--c2);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
}

.divForm h1 {
  color: var(--ap);
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 32px;
}

.submitButton {
  border-radius: 12px;
  background: var(--gvb);
  border: none;
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--c2);
  font-family: var(--type-second);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
  text-transform: uppercase;
  margin-top: 40px;
  width: fit-content;
  align-self: center;
}

.modal {
  position: absolute;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background: #ededed;
  padding: 40px 60px;
}

.modal h2 {
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: var(--a2);
  margin-bottom: 20px;
}

.modal p {
  white-space: pre-wrap;
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: var(--a1);
  margin-bottom: 32px;
  width: 100%;
}

.modal button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 10px;
  border-style: none;
  background: var(--a2);
  border-radius: 5px;
  font-family: var(--type-second);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--c1);
  width: fit-content;
}

.modal button:hover {
  cursor: pointer;
}

.error {
  align-self: center;
  margin-top: 20px;
  color: var(--v2);
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

@media (max-width: 1024px) {

  .containerPrincipal,
  .divIntro,
  .divForm {
    padding: 0 20px;
    margin: 0;
  }

  .divIntro,
  .divForm {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;
  }

  .divIntro p,
  .divForm h1 {
    grid-column: 1 / -1;
  }
}

@media (max-width: 768px) {
  .divIntro h1 {
    font-size: 48px;
    line-height: 56px;
  }

  .divIntro p {
    font-size: 18px;
    line-height: 22px;
  }

  .divForm h1 {
    font-size: 32px;
    line-height: 36px;
  }

  .modal h2 {
    font-size: 28px;
    line-height: 32px;
  }

  .modal p {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 480px) {
  .containerPrincipal {
    display: block;
    padding: 0;
  }

  .divIntro,
  .divForm {
    padding: 40px 20px;
    margin: 0;
  }

  .divForm {
    margin-bottom: 120px;
  }

  .divIntro h1 {
    font-size: 28px;
    line-height: 32px;
  }

  .divIntro p {
    font-size: 16px;
    line-height: 24px;
  }

  .divForm h1,
  .modal h2 {
    font-size: 24px;
    line-height: 28px;
  }

  .modal p {
    font-size: 16px;
    line-height: 24px;
  }

  .submitButton {
    padding: 12px 24px;
    font-size: 16px;
  }
}