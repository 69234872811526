.containerPrincipal {
  padding: 80px 40px 0 40px;
  width: 100%;
}

.video {
  max-width: 960px;
  max-height: 540px;
  min-width: 480px;
  min-height: 270px;
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 12px;
}

.divTitulo {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.divTitulo button {
  margin-top: 0;
  align-self: flex-start;
}

.videoDesc {
  display: grid;
  grid-template-columns: 3fr 2fr;
  height: 540px;
}

.desc {
  margin-left: 20px;
  margin-top: 12px;
  font-family: var(--type-fisrt);
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: var(--c9);
}

.desc span {
  font-weight: 500;
  color: var(--c10);
}
