.containerPrincipal{
  max-width: 100%;
  padding: 200px 0;
  margin: auto;
  background: var(--gac1);
}

.form{
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 560px;
  max-height: 518px;
  padding: 80px 60px;
  background: var(--w);
  border-radius: 12px;
  button {
    margin-top: 32px;
  }
  h3 {
    align-self: center;
    margin-bottom: 32px;
    color: var(--a4);
    font-family: var(--type-first);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  a {
    display: flex;
    justify-content: end;
    margin-top: -12px;
    p {
      color: var(--a1);
      font-family: var(--type-second);
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
    }
  }
}

.fourth{
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: none;
  width: 100%;
  background: var(--gab2);
  color: var(--c2);
  font-family: var(--type-second);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  transition: .3s;
}

.fourth:hover{
  transform: translateY(-4px);
  cursor: pointer;
  transition: .3s;
}

.error {
  align-self: center;
  margin-top: 20px;
  color: var(--v2);
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.fourth:disabled,
.fourth:disabled:hover {
  background: #ccc;
  color: #666;
  transform: none;
  cursor: default;
}