.main {
  display: flex;
  margin: auto;
  width: 100%;
}

.svg {
  margin: 8px 12px;
  width: 32px;
  height: 32px;
  fill: var(--v2);
  transition: .3s;
  transform: rotate(180deg);
}

.svgReverse {
  margin: 8px 12px;
  width: 32px;
  height: 32px;
  fill: var(--c9);
  transition: .3s;
}

.toggleButton {
  position: absolute;
  z-index: 1001;
}

@media (min-width: 768px) {
  .toggleButton {
    display: none;
  }
}