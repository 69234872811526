.containerHeader {
  display: flex;
  align-items: center;
  max-width: 100vw;
  height: 88px;
  padding-left: calc((100vw - 1600px - 17px) / 2);
  padding-right: calc((100vw - 1600px - 17px) / 2);
  background: var(--c1);
  margin: auto;
  z-index: 1003;
}

.buttonsMenu button {
  margin-right: 20px;
}

.menu {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  z-index: 1003;
}

.divMenu {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 80px 0 70px;
}

.itensMenu {
  display: flex;
  color: var(--C10);
  font-family: var(--type-second);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.itemMenu {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  transition: .3s;
}

.itemMenu:last-child {
  margin-right: 0;
}

.itemMenu:hover {

  .item,
  .selectedResp {
    color: var(--v2);
  }

  /* background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  transition: .3s;
  transform: translateY(-2px);
}

.selected {
  background: var(--gv2);
  width: 48px;
  height: 4px;
  border-radius: 20px;
  transition: .3s;
}

.opaco {
  background: var(--gv2);
  width: 48px;
  height: 4px;
  border-radius: 20px;
  opacity: 0;
}

.selected:last-child {
  margin-right: 0;
}

.logo {
  margin-right: 70px;
}

.checkboxMenu {
  display: none;
}

@keyframes showElement {
  from {
    display: none;
    transform: translateX(100vw);
  }

  to {
    display: flex;
    transform: translateX(-100vw);
  }
}

@keyframes hideElement {
  from {
    left: 0;
    display: flex;
  }

  to {
    left: 100%;
    display: none;
  }
}

@media (max-width: 1024px) {
  .containerHeader {
    display: flex;
    padding: 0 20px;
    height: 60px;
    width: 100vw;
    background: var(--c1);
    align-items: center;
  }

  .buttonsMenu {
    display: none;
  }

  .logo {
    /* width: 120px;
    height: 24px; */
    margin: 0;
  }

  .divMenu {
    margin: 0;
  }

  .botoes {
    display: none;
  }

  .sep {
    display: none;
  }

  .nav {
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: space-between;
  }

  .itensMenu {
    display: none;
    position: absolute;
    top: 60px;
    flex-direction: column;
    width: 100vw;
    justify-content: center;
    align-items: center;
    left: 100%;
    /* transform: translateX(100vw);
    transition: 0.5s ease transform; */
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .itemMenu:hover {
    .item {
      color: var(--v2)
    }

    .selectedResp {
      color: var(--w)
    }

    transform: none;
  }

  .escondido {
    animation: hideElement 0.5 ease-out forwards;
  }

  .itensMenu> :last-child {
    border: none;
  }

  .itemMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 8px 0;
    width: 100%;
    background-color: #f7f7f7;
    border-bottom: 1px solid #cccccc;
  }

  .selected,
  .opaco {
    display: none;
  }

  .selectedResp {
    display: flex;
    justify-content: center;
    padding: 8px 0;
    margin: -8px 0;
    width: 100%;
    text-decoration: none;
    color: var(--w);
    background: var(--gv2);
    transition: 0.3s ease;
    border: none;
    /* scale: 1; */
  }

  .item {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .menuButtonContainer {
    display: flex;
    cursor: pointer;
    position: absolute;
    right: 3%;
    display: block;
    height: 22px;
    width: 30px;
  }

  .menuButtonContainer span {
    position: absolute;
    display: block;
    height: 5px;
    width: 100%;
    background: #707070;
    border-radius: 30px;
    transition: 0.25s ease-in-out;
  }

  .menuButtonContainer span:nth-child(1) {
    top: 0;
  }

  .menuButtonContainer span:nth-child(2) {
    top: 8px;
  }

  .menuButtonContainer span:nth-child(3) {
    top: 16px;
  }

  .checkboxMenu:checked+label span:nth-child(1) {
    transform: rotate(-45deg);
    background: brown;
    top: 8px;
  }

  .checkboxMenu:checked+label span:nth-child(2) {
    display: none;
  }

  .checkboxMenu:checked+label span:nth-child(3) {
    transform: rotate(45deg);
    background: brown;
    top: 8px;
  }

  .checkboxMenu:checked~nav .itensMenu {
    display: flex;
    /* transform: translateX(-100%);
    transition: 0.5s ease transform; */
    animation-name: showElement;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
}