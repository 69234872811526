.menu {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: calc(110vh - 88px);
  max-width: 380px;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
  padding: 60px 40px 0 40px;
  background: var(--gpa2);
  z-index: 1000;
}

.divTitulo {
  margin-bottom: 40px;
}

.divTitulo h1 {
  font-family: var(--type-first);
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  color: var(--c2);
}

.divTitulo h1 span {
  color: var(--a1);
}

.sep {
  display: inline-block;
  background: var(--c9);
  width: 340px;
  height: 2px;
  margin-left: -20px;
  border-radius: 4px;
  margin-top: 20px;
}

.divMenu {
  display: flex;
  flex-direction: column;
}

.itemMenu {
  font-family: var(--type-first);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: var(--c3);
  margin-bottom: 20px;
  transition: 0.3s;
  width: fit-content;
}

.itemMenu:hover {
  transform: translateX(5px);
  color: var(--a1);
}

.selected {
  font-family: var(--type-first);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
  transition: 0.3s;
  color: var(--a1);
}

.menuConv {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 8px;
  transition: 0.5s;
}

.itemConvenio {
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
  transition: 0.3s;
  color: var(--c3);
}

.selectedConv {
  font-family: var(--type-second);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
  transition: 0.3s;
  color: var(--a1);
}

.itemConvenio:hover {
  transform: translateX(5px);
  color: var(--a1);
}

.sair {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
  transition: 0.3s;
}

.sair p {
  font-family: var(--type-second);
  color: var(--c3);
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  transition: 0.3s;
}

.svg {
  margin-left: 8px;
  width: 32px;
  height: 32px;
  fill: var(--c3);
  transition: 0.3s;
}

.sair:hover {
  cursor: pointer;
  transform: translateX(5px);
}

.sair:hover .svg,
.sair:hover p {
  fill: var(--v2);
  color: var(--v2);
}

@media (max-width: 768px) {
  .menu {
    position: fixed;
    min-height: calc(100vh - 60px);
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }

  .menuVisible {
    transform: translateX(-100%);
  }
}
