.containerPrincipal {
  padding: 80px 40px 0 40px;
  width: 100%;
}

.divAviso {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.10);
  margin-bottom: 200px;
}

.divAviso a {
  font-family: var(--type-second);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--a2);
  margin-bottom: 20px;
  transition: .3s;
  width: fit-content;
}

.divAviso a:hover {
  color: var(--a9)
}

.mensagem {
  display: flex;
  flex-direction: column;
}

.mensagem>label {
  color: var(--c10);
  font-family: var(--type-first);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
}

.mensagem textarea {
  width: 100%;
  min-height: 400px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--c2);
  background: var(--c-15);
  padding: 12px;
  border: 1px solid #ededed;
  color: var(--c7);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.mensagem textarea:hover,
.mensagem textarea:focus {
  outline: none;
  border-color: #0B5690;
  background: white;
  box-shadow: 0px 0px 0px 3px rgba(11, 86, 144, 0.30);
  ;
}

.ativarAviso {
  display: flex;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.modal {
  position: absolute;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background: #ededed;
  padding: 40px 60px;
}

.modal h2 {
  font-family: var(--type-first);
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: var(--a2);
  margin-bottom: 20px;
}

.modal p {
  white-space: pre-wrap;
  font-family: var(--type-second);
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: var(--a1);
  margin-bottom: 32px;
  width: 100%;
}

.modal button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 10px;
  border-style: none;
  background: var(--a2);
  border-radius: 5px;
  font-family: var(--type-second);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--c1);
  width: fit-content;
}

.modal button:hover {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .containerPrincipal {
    padding: 60px 20px 0 20px;
  }

  .divForm {
    padding: 40px 20px;
    margin-bottom: 120px;
  }

  .modal {
    padding: 32px;
    width: calc(100vw - 40px);
  }

  .modal h2 {
    font-size: 24px;
    line-height: 28px;
  }

  .modal pre {
    font-size: 16px;
    line-height: 24px;
  }

  .modal button {
    font-size: 16px;
    line-height: 24px;
  }
}